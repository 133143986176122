body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip {
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    position:relative;
    left:  5px;
    top:  -15px;
    z-index: 999;
    opacity: 0.8;
}

.tooltip-gb {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
	  font-weight: 1000;
    background-color: rgba(0, 0, 0, 1) !important;
    position:relative;
    left:  5px;
    top:  -15px;
    z-index: 999;
    opacity: 1;
}

.tooltip-ranking {
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    background-color: rgba(255, 120, 0, 0.8) !important;
    position:relative;
    left:  -10px;
    top:  0px;
    z-index: 999;
    width: 10px;
    opacity: 0.8;
}

.tooltip-ranking-roadrace {
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    background-color: rgba(255, 120, 0, 0.8) !important;
    position:relative;
    left:  5px;
    top:  -15px;
    z-index: 999;
    width: 30px;
    opacity: 0.8;
}

.tooltip-ranking-gb {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
	  font-weight: 1000;
    background-color: rgba(255, 120, 0, 0.8) !important;
    position:relative;
    left:  -10px;
    top:  5px;
    z-index: 999;
    width: 15px;
    opacity: 1;
}

.tooltip-ranking-roadrace-gb {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
	  font-weight: 1000;
    background-color: rgba(255, 120, 0, 0.8) !important;
    position:relative;
    left:  5px;
    top:  -15px;
    z-index: 999;
    width: 42px;
    opacity: 1;
}
.tooltip-marker {
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    position:relative;
    left: 5px;
    top:  0px;
    opacity: 0.8;
}

.tooltip-name {
    margin-left: 1em;
}

.tooltip-speed {
    margin-left: 1em;
}

.tooltip-time {
    margin-left: 1em;
}

.logo {
  height: 8vh;
}

.athletes{
  width: 100vw;
  height: 91vh;
  background-color: #EEEEEE;
}
.athletes_mobile{
  width: 100vw;
  height: 79vh;
  background-color: #EEEEEE;
}
.settings{
  width: 100vw;
  height: 91vh;
  background-color: #EEEEEE;
}
.settings_mobile{
  width: 100vw;
  height: 79vh;
  background-color: #EEEEEE;
}

.online{
  position: absolute;
  font-size: xx-small;
  color: #00FF00;
  top: 100px;
  left: 80px;
  zIndex: 100;
}

.offline{
  position: absolute;
  font-size: xx-small;
  color: #FF0000;
  top: 100px;
  left: 80px;
  zIndex: 100;
}

.nowtime{
  text-align: right;
  color: #FFFFFF;
}
.endtime{
  text-align: right;
  color: #FFFFFF;
}

.play_speed{
  width : 55px;
	margin-top: 20px;
}

.hidden_tab{
  display : none;
}

.show_tab{
  display : block;
}

.wind_panel{
  position: absolute;
  font-size: xx-small;
  color: #00FF00;
  top: 50px;
  right: 20px;
  zIndex: 100;
}
/* OpenLayer航跡マップ上に風向・風速をオーバレイするDiv設定 */
.wind-direction-icon {
   color: #FFFFFF;
   font-size: 9pt;
   z-index: 100;
   position: absolute;
   width: 0px!important;
   height: 0px!important;
   border-radius: 50%;
   border: 1px solid white;
   background-color: rgb(50, 52, 58);
   top: 15px;
   right: 30px;
}

/* 子要素をセンタリングする基本設定 */
.wind-direction-icon > .top-label,
.wind-direction-icon > .arrow-label,
.wind-direction-icon > .bottom-label {
   position: absolute;
   display: block;
   top: 50%;
   left: 50%;
   -ms-transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
}


.wind-direction-icon > .top-label {
  top:-15px!important;
}

/* WIND ラベル位置調整 */
.wind-direction-icon > .top-label {
   top: 0%;
}

/* 風向アイコン位置調整 */
.wind-direction-icon > .arrow-label > .arrow-img {
   width: 15px;
   height: 15px;
}

/* 風速 ラベル位置調整 */
.wind-direction-icon > .bottom-label {
  top: 75%;
  margin-top: 25px!important;
  margin-left: 5px!important;
}

.wind-unit{ font-size: small; }

.btn-athlete {
  width: 100%;
  min-width: 360px;
	height: 30px;
	text-align: left;
	background-color  : #333;
	border: 1px solid #222;
}
.btn-athlete-selected {
  width: 100%;
  min-width: 360px;
	height: 30px;
	text-align: left;
	background-color  : #333;
	border: 1px solid aqua;
}

.athlete-head {
	width  : 20px;
	height : 20px;
  display: inline-block;
  padding: 2px;
	color: #FFFFFF;
}


.athlete-no {
	width  : 80px;
	color: #FFFFFF;
	margin-right : 5px;
	text-align: center;
	display: inline-block;
	margin-right: 20px;
	margin-left: 20px;
	font-size: 16px;
	font-weight: 700;
}
.athlete-no-selected {
	width  : 80px;
	color: #FFFFFF;
	margin-right : 5px;
	text-align: center;
	display: inline-block;
	border-bottom: 1px solid currentColor;
	margin-right: 20px;
	margin-left: 20px;
	font-size: 16px;
	font-weight: 700;
}
.athlete-name-without-no {
	width  : 250px;
	color: #FFFFFF;
	display: inline-block;
	white-space: nowrap;
}
.athlete-name-selected-without-no {
	width  : 250px;
	color: #FFFFFF;
	display: inline-block;
	white-space: nowrap;
}
.athlete-name {
	width  : 170px;
	color: #FFFFFF;
	display: inline-block;
	white-space: nowrap;
}
.athlete-name-selected {
	width  : 170px;
	color: #FFFFFF;
	display: inline-block;
	white-space: nowrap;
}

.select-speed {
	height : 30px;
	background-color: #FFFFFF;
}

.athlete-spd {
	color: #FFFFFF;
}

.athlete-spd-max {
	color: rgba(255, 127, 80, 1);
}

.athlete-unit {
	color: #FFFFFF;
}

.athlete-unit-max {
	color: rgba(255, 127, 80, 1);
}

.heat-time {
  z-index:1;
  font-weight: 900;
	font-size: large;
  padding-left : 5px;
  position:fixed;
  right:30px;
  top:10px;
  width:90px;
  height:23px;
  color: rgba(255, 255, 255, 1);
	text-shadow: 1px 1px 0 #1B7E85
}

.speed-back {
    font-weight: 900;
		font-size: medium;
    color: rgba(255, 255, 255, 1);
    padding-top : 10px;
}
.discipline {
    color: rgba(255, 255, 255, 1);
    font-weight: 900;
		text-shadow: 1px 1px 0 #1B7E85;
    padding-left : 10px;
    padding-bottom : 5px;
		border-bottom: 1px solid currentColor;
}

.speed-container {
    color: rgba(0, 0, 255, 1);
    font-weight: 900;
    height: 90px;
		background: linear-gradient(180deg, #032424 58.33%, #1C666F 85.42%, #5192A1 99.48%);
}

.bike-speed-container {
    background-color: rgba(0, 255, 0, 1);
		width: 100vw;
    height: 100vh;
}

.speed-head {
    padding-left : 10px;
		width:80px;
    font-weight: 900;
		font-size: medium;
    color: rgba(255, 255, 255, 1);
}

.speed-data {
		width: 200px;
    padding-left : 7px;
    color: rgba(32, 56, 100, 1);
    margin-right : 10px;
		font-size: medium;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
}
.speed-head-max {
    color: rgba(255, 127, 80, 1);
    font-weight: 900;
    margin-left : 10px;
}
.speed-data-max {
    color: rgba(255, 127, 80, 1);
    font-weight: 900;
    padding-left : 10px;
}


.heat-none {
}

.heat-red {
  z-index:1;
  padding-left : 5px;
  position:fixed;
  right:140px;
  top:12px;
  width:50px;
  height:20px;
  background-color: #ff2222;
}
.heat-yellow {
  z-index:1;
  padding-left : 5px;
  position:fixed;
  right:140px;
  top:12px;
  width:50px;
  height:20px;
  background-color: #ffd700;
}
.heat-green {
  z-index:1;
  padding-left : 5px;
  position:fixed;
  right:140px;
  top:12px;
  width:50px;
  height:20px;
  background-color: #008000;
}

.ax-on {
  background-color: #00ff00;
	width: 100%;
	height: 10vh;
}

.race-head {
  background-color: #2A373D;
	width: 280px;
	height: 50px;
	margin-left: 8px;
}

.race-name {
	margin-left: 15px;
	color: #fff;
	font-weight: 1000;
}

.lap-head {
  background-color: #000000;
	height: 50px;
}

.lap-disp {
	margin-left: 15px;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 1000;
}
.menu-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.panel-container {
  width: 300px;
  padding: 15px;
}

.menu-item {
  border-top: #BABABA 1px solid;
  display: flex;
  flex-direction: column;
}

.menu-item-title {
  margin-top: 10px;
  margin-bottom: 5px;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-content {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.panel-content-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.focus_check{
  content: '';
  width: 10px;
  height: 5px;
  border-left: 2px solid #25AF01;
  border-bottom: 2px solid #25AF01;
  transform: rotate(-45deg);
}

.ranking-row{
  padding: 30px;
}


.ranking-athlete-no {
	color: #FFFFFF;
	text-align: center;
	display: inline-block;
	width: 100%;
	font-size: 16px;
	font-weight: 700;
}

.ranking-athlete-no-selected {
	color: #FFFFFF;
	text-align: center;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid currentColor;
	font-size: 16px;
	font-weight: 700;
}

.nation-flag{
	width : 24px;
	height: 18px;
}

.ranking-dist{
	color: #FFFFFF;
	text-align: center;
	font-size: 15px;
	width: 100%;
	display: inline-block;
}

.ranking-name{
  width: 150px;
	color: #FFFFFF;
	text-align: center;
	width: 100%;
	display: inline-block;
}

.ranking-firstname{
	color: #FFFFFF;
	text-align: center;
	font-weight: 800;
	font-size: 16px;
}
.ranking-spd{
	color: #FFFFFF;
	text-align: center;
	display: inline-block;
	width: 100%;
	font-size: 16px;
}

.ranking-spd-max{
	color: rgba(255, 127, 80, 1);
	text-align: center;
	display: inline-block;
	width: 100%;
	font-size: 16px;
}

.nation-flag-2{
	width : 21px;
	height: 16px;
}

.goal-flag{
	width : 16px;
	height: 16px;
}

.team-flag{
	width : 21px;
	height: 21px;
}

.ranking-rank{
  width:  21px;
  height: 21px;
  color:  white;
  background-color: dimgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*** iOS ***/
.switch__label {
  width: 50px;
  position: relative;
  display: inline-block;
}
.switch__content {
  display: block;
  cursor: pointer;
  position: relative;
  border-radius: 30px;
  height: 31px;
overflow: hidden;
}
.switch__content:before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  top: 0;
  left: 0;
  border: 1.5px solid #E5E5EA;
  border-radius: 30px;
  background-color: #fff;
}
.switch__content:after {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  border-radius: 30px;
  -webkit-transition: all .5s;
     -moz-transition: all .5s;
      -ms-transition: all .5s;
       -o-transition: all .5s;
          transition: all .5s;
}
.switch__input {
  display: none;
}

.switch__circle {
  display: block;
  top: 2px;
  left: 2px;
  position: absolute;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 6px #999;
          box-shadow: 0 2px 6px #999;
  width: 27px;
  height: 27px;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  background-color: #fff;
  -webkit-transition: all .5s;
     -moz-transition: all .5s;
      -ms-transition: all .5s;
       -o-transition: all .5s;
          transition: all .5s;
}
.switch__input:checked ~ .switch__circle {
  left: 21px;
}

.switch__input:checked ~ .switch__content:after {
  background-color: #4BD964;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.speedometer {
  width: 300px;
  height: 200px;
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gauge {
  width: 100%;
  height: auto;
}

.gauge-background {
  stroke-linecap: round;
}

.gauge-value {
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.speed-text {
  font-size: 24px;
  fill: #007bff;
  font-weight: bold;
}

.unit-text {
  font-size: 12px;
  fill: #666;
}

.digital-speed {
  text-align: center;
  font-size: 36px;
  color: #007bff;
  margin-top: 20px;
  font-weight: bold;
}

.delayed-time{
	position: fixed;
	bottom: 140px;
	left: 130px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.7); /* 背景色と透明度 */
	color: white; /* 文字色 */
	font-size: 16px; /* 文字サイズ */
	text-align: right;
}
/* Mobile view (window width < 500px) */
@media (max-width: 499px) {
  .delayed-time {
    bottom: 92%; /* Adjusted position for mobile */
    left: 250px; /* Adjusted position for mobile */
		height: 35px;
    font-size: 14px; /* Smaller font size for mobile */
    width: 100px; /* Smaller font size for mobile */
  }
}

/* PC view (window width >= 500px) */
@media (min-width: 500px) {
  .delayed-time {
    bottom: 140px; /* Original position for PC */
    left: 130px; /* Original position for PC */
    font-size: 16px; /* Original font size for PC */
  }
}

/*  Spinner  */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 他の要素の上に表示 */
}

.loader-container {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
